<template>
  <b-container class="d-flex align-items-center min-vh-100">
    <b-row class="w-100 justify-content-center m-0">
      <b-col md="6">
        <div class="w-100">
          <div class="clearfix pl-lg-5">
            <h1 class="float-left display-3 mr-4">403</h1>
            <h4 class="pt-3">Forbidden</h4>
            <p class="text-muted">
              You don't have the required permissions to access this page. 
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue';

export default {
  name: 'Page403',
  components: {
    BContainer,
    BRow,
    BCol
  }
};
</script>